import React from 'react'
import PropTypes from 'prop-types'

// Components
import PageLayout from '../components/Layout/PageLayout'
import ContactForm from '../components/Forms/ContactForm'

const Contact = ({ location }) => {
  return (
    <PageLayout location={location} title="Contact" pageTitle="Contact Us" showNews={true}>
      <p>
        We are always happy to hear from you and connect with other model train enthusiasts. Drop us
        a line anytime for any model train-related questions, inquiries, or proposals. A friendly
        member of our customer service team will get back to you ASAP.
      </p>
      <ContactForm />
    </PageLayout>
  )
}

Contact.propTypes = {
  location: PropTypes.object.isRequired,
}

export default Contact
