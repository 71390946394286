import React from 'react'
import { Formik, Form } from 'formik'
import { Toaster } from 'react-hot-toast'

// Components
import TextForm from './TextForm'
import BtnOutline from '../Buttons/BtnOutline'

// Utils
import { formSubmitter } from '../../utils/form'

const formName = 'contact-form'

// https://www.derekaspaulding.com/blog/simple-contact-form-with-gatsby-formik-and-netlify/
// https://www.freakyjolly.com/react-forms-with-validation-example/
const ContactForm = () => {
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="container my-5">
        <div className="col-10 offset-1">
          <Formik
            initialValues={{ name: '', email: '', subject: '', content: '' }}
            validate={({ name, email, subject, content }) => {
              const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
              const errors = {}

              if (!name || name.trim().length === 0) errors.name = 'Name Required'
              if (!email || !emailRegex.test(email)) errors.email = 'Valid Email Required'
              if (!subject || subject.trim().length === 0) errors.subject = 'Subject Required'
              if (!content || subject.trim().content === 0) errors.content = 'Message Required'

              return errors
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              return await formSubmitter(formName, values, setSubmitting, resetForm)
              // if (process.env.NODE_ENV === 'development') {
              //   return setTimeout(() => {
              //     toast.success(JSON.stringify(values, null, 2))
              //     setSubmitting(false)
              //     resetForm()
              //   }, 1000)
              // }

              // const options = {
              //   headers: {
              //     method: 'POST',
              //     'Content-Type': 'application/x-www-form-urlencoded',
              //   },
              // }
              // return await axios
              //   .post('/', encode({ 'form-name': formName, ...values }), options)
              //   .then(() => {
              //     toast.success('Sent email')
              //     resetForm()
              //   })
              //   .catch(e => {
              //     console.log(e)
              //     toast.error('There was an error sending your email')
              //   })
              //   .finally(() => {
              //     setSubmitting(false)
              //   })
            }}
          >
            {({ isSubmitting }) => (
              <Form
                className="contact-form"
                method="post"
                netlify-honeypot="bot-field"
                data-netlify="true"
                name={formName}
              >
                <TextForm name="name" label="full name" placeholder="Please enter your name" />
                <TextForm
                  name="email"
                  label="email address"
                  type="email"
                  placeholder="Please enter your email"
                />
                <TextForm name="subject" label="subject" placeholder="Please enter your subject" />
                <TextForm
                  name="content"
                  label="content"
                  placeholder="Please enter your message"
                  as="textarea"
                  rows="5"
                />
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value={formName} />
                <div className="form-group mb-5">
                  <BtnOutline type="submit" size={'lg'} disabled={isSubmitting}>
                    {isSubmitting ? 'Please wait...' : 'Submit'}
                  </BtnOutline>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default ContactForm
